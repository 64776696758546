import { IAerialLiftChecklist } from "./aerialLiftChecklist";
import { IChemical } from "./chemicalInv";
import { IComment } from "./comment";
import { IConfinedSpaceA } from "./confinedSpaceA";
import { IDiscipline, DisciplineFormValues } from "./discipline";
import { IFallHaz } from "./fallHaz";
import { IHazard } from "./hazard";
import { INotification } from "./notification";
import { ISiteSafetyBriefing } from "./siteSafetyBriefing";
import { ITrafficControl } from "./trafficControl";

export interface ISafetyChecklistEnvelope {
    safetyChecklists: ISafetyChecklist[];
    safetyChecklistCount: number;
}

export interface ISafetyChecklist {

    id: string;
    created: Date;
    lastModified: Date;
    lastModifiedBy?: string;
    submitterEmail: string;
    discipline_id: string;
    discipline?: IDiscipline;
    disciplineName?: string;
    projectManagerEmail: string;

    projectNumber: string;
    projectName: string;
    projectAddress: string;
    siteSafetyLeaderEmail: string;

    otherAssignedPersonnelEmails?: string[];
    estimatedStartDate: Date;
    estimatedCompletionDate: Date;

    emergencyCoordinatorName?: string;
    emergencyCoordinatorEmail: string;
    emergencyReportingNumber: string;
    emergencySignal: string;

    evacRouteAndMusterPoint: string;
    emergencyMedicalFacility: string;
    medicalFacilityAddress: string;
    medicalFacilityNumber: string;

    files: string[];
    
    hazard_ids?: string[];
    hazards?: IHazard[];
    outdoorsGeneral_ids?: string[];
    structuresBuildings_ids?: string[];
    activeRoadways_ids?: string[];
    railroad_ids?: string[];
    construction_ids?: string[];
    trenching_ids?: string[];
    waterfront_ids?: string[];
    waste_ids?: string[];
    confinedSpaces_ids?: string[];
    scaffolds_ids?: string[];
    chemical_ids?: string[];
    pipeline_ids?: string[];
    wastewater_ids?: string[];

    additionalHazards?: IHazard[];
    additionalHazards_ids?: string[];

    chemicalList?: IChemical[];
    fallHazardAssessments?: IFallHaz[];
    trafficControlAssessments?: ITrafficControl[];
    confinedSpaceAssessments?: IConfinedSpaceA[];
    siteSafetyBriefings?: ISiteSafetyBriefing[];
    aerialLifts?: IAerialLiftChecklist[];

    otherItems?: string[];

    notifications?: INotification[];

    comments?: IComment[];

    deleted: boolean;
}

export class SafetyChecklistFormValues implements ISafetyChecklist {

    id: string = '';
    created: Date = new Date();
    lastModified: Date = new Date();
    lastModifiedBy?: string | undefined = undefined;
    submitterEmail: string = '';
    discipline_id: string = '';
    discipline: IDiscipline = new DisciplineFormValues();
    disciplineName?: string | undefined = undefined;
    projectManagerEmail: string = '';

    projectNumber: string = '';
    projectName: string = '';
    projectAddress: string = '';
    siteSafetyLeaderEmail: string = '';

    otherAssignedPersonnelEmails: string[] = [];
    estimatedStartDate: Date = new Date();
    estimatedCompletionDate: Date = new Date();

    emergencyCoordinatorName: string = '';
    emergencyCoordinatorEmail: string = '';
    emergencyReportingNumber: string = '';
    emergencySignal: string = '';

    evacRouteAndMusterPoint: string = '';
    emergencyMedicalFacility: string = '';
    medicalFacilityAddress: string = '';
    medicalFacilityNumber: string = '';

    hazard_ids: string[] = [];
    hazards: IHazard[] = [];
    outdoorsGeneral_ids: string[] = [];
    structuresBuildings_ids: string[] = [];
    activeRoadways_ids: string[] = [];
    railroad_ids: string[] = [];
    construction_ids: string[] = [];
    trenching_ids: string[] = [];
    waterfront_ids: string[] = [];
    waste_ids: string[] = [];
    confinedSpaces_ids: string[] = [];
    scaffolds_ids: string[] = [];
    chemical_ids: string[] = [];
    pipeline_ids: string[] = [];
    wastewater_ids: string[] = [];

    files: string[] = [];

    siteSafetyBriefings?: ISiteSafetyBriefing[] | undefined;
    chemicals: IChemical[] = [];
    additionalHazards: IHazard[] = [];
    additionalHazards_ids: string[] = [];
    otherItems: string[] = [];

    notifications?: INotification[] = [];

    deleted: boolean = false;

    constructor(init?: SafetyChecklistFormValues) {
        Object.assign(this, init);
    }
}


export interface ISafetyChecklistSearch {
    disciplines: string[];
    months: number[];
    years: number[];
    projectNumber: string;
    offices: string[];
  }
  export class SafetyChecklistSearchFormValues implements ISafetyChecklistSearch {
    disciplines: string[] = [];
    months: number[] = [];
    years: number[] = [];
    projectNumber: string = '';
    projectName: string = '';
    offices: string[] = [];
    
    constructor(init?: ISafetyChecklistSearch) {
      Object.assign(this, init);
    }
  }

  export interface ISafetyChecklistGuidSearch{
    projectNumber: string;
    discipline_id: string;
  }
  export class SafetyChecklistGuidSearchFormValues implements ISafetyChecklistGuidSearch {
    projectNumber: string = '';
    discipline_id: string = '';
  }