import React, { useContext, useState } from "react";
import { Button, Grid, Segment, InputOnChangeData } from "semantic-ui-react";
import { RootStoreContext } from "../../app/stores/rootStore";
import { Input } from "semantic-ui-react";
import { IProjectSearchParams } from "../../app/models/IFilterParams";
import { Link } from "react-router-dom";

const initialValues: IProjectSearchParams = {
    projectNumber: '',
}


const ProjectValidator = () => {
  const rootStore = useContext(RootStoreContext);
  const {
    // modal: { open, body },
    closeModal,
  } = rootStore.modalStore;
  const {
    checkProjectNumber,
  } = rootStore.generalStore;

  const [validProject, setValidProject] = useState(false);
  const [values, setValues] = useState(initialValues);
  const [projectNumberChecked, setProjectNumberChecked] = useState(false);

  const handleProjectChange = (event: React.SyntheticEvent<HTMLElement>, data: InputOnChangeData) => { setValues({ ...values, projectNumber: data.value }); };

  const search = async() => {
    let response = await checkProjectNumber(values.projectNumber);
    setValidProject(response.retval);
    setProjectNumberChecked(true);
  }

  return (
    <Segment clearing>
      <Grid columns={1}>
      <p><b>Please enter the Project Number you wish to make a Checklist for. If the project is not yet in BST or was recently entered, it may not validate.</b></p>
        <Grid.Column textAlign="center">
            <Input placeholder='Project Number' onChange={handleProjectChange} value={values.projectNumber} />
        </Grid.Column>
        {projectNumberChecked && <>
        <Grid.Column textAlign="center">
            <div>Project Found in BST: {validProject? 'Yes' : 'No'}</div>
        </Grid.Column>
        </>}

        <Grid.Column>
            <Button disabled={!values.projectNumber || !projectNumberChecked} as={Link} to={`/new-safety-checklist/${values.projectNumber}`} onClick={() => closeModal()} color="orange" floated="right" content="Submit"/>
            <Button disabled={!values.projectNumber} color="green" floated="right" onClick={async() => await search()} content='Check BST' />
        </Grid.Column>
      

      </Grid>
    </Segment>
  );
};

export default ProjectValidator;
