import React, { useContext } from "react";
import { Button, Grid, Image, Segment } from "semantic-ui-react";
import { RootStoreContext } from "../../app/stores/rootStore";

const AboutForm = () => {
  const rootStore = useContext(RootStoreContext);
  const {
    closeModal,
  } = rootStore.modalStore;

  return (
    <Segment clearing>
      <Grid columns={2}>
        <Grid.Column>
          <Image size="small" src="/assets/ced3.png" wrapped />
        </Grid.Column>
        <Grid.Column textAlign="center">
          <h3>Version 1.5.7</h3>
          <h4>November 15th, 2024</h4>
        </Grid.Column>
      </Grid>

      <Button color="blue" floated="right" onClick={() => closeModal()}>
        Ok
      </Button>
    </Segment>
  );
}

export default AboutForm;