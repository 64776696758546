import React, { ReactNode, FC } from "react";

interface IRenderIfProps {
    condition: boolean
    children: ReactNode
}

const RenderIf: FC<IRenderIfProps> = ({condition,children}) => condition? <>{children}</> : null

export default RenderIf;
