import React, { useContext, useState, useEffect } from "react";
import { Form as FinalForm, Field } from "react-final-form";
import { FieldArray } from "react-final-form-arrays";
import arrayMutators from "final-form-arrays";
import { Form, Segment, Button, Header, Grid, Step } from "semantic-ui-react";
import { observer } from "mobx-react-lite";
import { RouteComponentProps } from "react-router-dom";

import { RootStoreContext } from "../../../app/stores/rootStore";
import PageTitle from "../../../app/common/form/PageTitle";
import {
	IFieldSafetyAudit,
	FieldSafetyAuditFormValues,
} from "../../../app/models/fieldSafetyAudit";
import {
	ICorrectiveAction,
	CorrectiveActionFormValues,
	ServerboundNewCorrectiveAction,
} from "../../../app/models/correctiveAction";
import { OtherItemFormValues } from "../../../app/models/otherItem";
import MultipleSelectInput from "../../../app/common/form/MultipleSelectInput";
import TextAreaInput from "../../../app/common/form/TextAreaInput";
import TextInput from "../../../app/common/form/TextInput";
import { combineValidators } from "revalidate";
import { fsaQuestions } from "../../../app/common/options/fsaQuestion";
import LoadingComponent from "../../../app/layout/LoadingComponent";
import { IfsaQuestionGroup, IQuestion } from "../../../app/models/fsaQuestion";
import { FieldFileDropZone } from "../../../app/common/form/FileDropZone";
import { SafetyChecklistFormValues } from "../../../app/models/safetyChecklist";
import RenderIf from "../../../app/common/form/RenderIf";
import ConfirmCancelForm from '../../modals/ConfirmCancelForm';
import { StepButtons } from "../../../app/common/form/StepButtons";


const validate = combineValidators({
	//brakesComment: isRequiredIf((values:any) => values.brakesAcceptable === true)("Brake Explanation"),
	//emergencyBrakeAcceptable: isRequired("Emergency Brake Condition"),
	// registrationComment: string;
	//insuranceAcceptable: isRequired("Insurance Status"),
	// insuranceComment: string;
	// description: composeValidators(
	//   isRequired("Description"),
	//   hasLengthGreaterThan(4)({
	//     message: "Description needs to be at least 5 characters",
	//   })
	// )(),
});

interface DetailParams {
	id: string;
	safetyChecklist_id: string;
  }

const FieldSafetyAuditForm: React.FC<RouteComponentProps<DetailParams>> = ({
	match,
	history,
}) => {
	const rootStore = useContext(RootStoreContext);
	const {
		createFieldSafetyAudit,
		editFieldSafetyAudit,
		submitting,
		loadFieldSafetyAudit,
		loadFSAInitial,
		users,
		loadingInitial,
	} = rootStore.fieldSafetyAuditStore;

	const {
		loadSafetyChecklist,
	} = rootStore.safetyChecklistStore;
	const { openModal } = rootStore.modalStore;

	const [fieldSafetyAudit, setFieldSafetyAudit] = useState(new FieldSafetyAuditFormValues());
	const [safetyChecklist, setSafetyChecklist] = useState(new SafetyChecklistFormValues());
	const [loading, setLoading] = useState(false);
	const [step, setStep] = useState(1);

	// will only need to check for changes going INTO step 2, so I should only check on nextStep since a later step can't affect the earlier answers, right?
	const nextStep = (values: any) => {
		// Why is this off by 1? is it because the state doesnt actually update until after nextStep completes?
		// Should the correctiveActions handler be called as a nextStep().finally type deal, or is that overcomplicating things?
		//console.log('pre-setStep: ' + step);
		setStep(step + 1);
		//console.log('post-setStep: ' + step);
		if(step === 2){
			//console.log('Calculating CAs...');
			handleCorrectiveActions(values);
		}
		// if (step === 3){
		// 	console.log('Going from Step 1 to Step 3');
		// 	console.log('Calculate Corrective Actions here....');
		// }

	  }
	
	  const prevStep = () => {
		setStep(step - 1);
	  }

	useEffect(() => {
		setLoading(true);
		if (match.params.safetyChecklist_id) {
			//console.log(match.params.safetyChecklist_id);
			//console.log('id found');
			// TODO recently changed type to ANY due to SC store change
			// does it still work? larger implications? better way to do this?
			loadSafetyChecklist(match.params.safetyChecklist_id).then((checklist: any) =>
				{
					setSafetyChecklist(checklist);
					fieldSafetyAudit.safetyChecklist_id = match.params.safetyChecklist_id;
					loadFSAInitial().finally(() => setLoading(false));
				}
			);
		}
		else if (match.params.id) {
			// editing FSA
			loadFSAInitial();
			loadFieldSafetyAudit(match.params.id)
				.then((fieldSafetyAudit) => {
					if (fieldSafetyAudit !== null && fieldSafetyAudit !== undefined) {
						setFieldSafetyAudit(new FieldSafetyAuditFormValues(fieldSafetyAudit));
						loadSafetyChecklist(fieldSafetyAudit.safetyChecklist.id).then((checklist: any) =>
						{
							setSafetyChecklist(checklist);
						});
					}
				}).finally(() => setLoading(false));
		}
		else {
			loadFSAInitial().finally(() => setLoading(false));
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	
	if (loadingInitial)
    return <LoadingComponent content="Loading Field Safety Audit..." />;

	const handleCorrectiveActions = (values: any) => {
		//console.log(values);
		//console.log(fsaQuestions);
		// use the values passed to make the same changes we need to .correctiveActions
		// on step change, check for existing CAs
		// if empty, just add them
		// if CAs exist, check against the values to see if any of them are no longer applicable
		if (values.correctiveActions && values.correctiveActions.length > 0) {
			//console.log("Corrective Actions exist! Check for existence first");
			// Check if a corrective action exists whose nonconformance matches search param
			for (const property in values) {
				let questionSet = property.replaceAll(/[0-9]/g, '');
				let answerNumber = property.replaceAll(/[A-Za-z]/g, '');
				let index = fsaQuestions.findIndex((questionGroup: IfsaQuestionGroup) => questionGroup.propName === questionSet);

				// Check to see if it's already in the array
				if (values[property] === "N") {
					let tempValue = fsaQuestions[index].questions;
					let qIndex = tempValue.findIndex((question: IQuestion) => question.key === parseInt(answerNumber));
					if (!values.correctiveActions.some((el: ICorrectiveAction) => el.nonConformance === tempValue[qIndex].question)) {
						//console.log("Found NC that isnt represented");
						let newCorrectiveAction = new CorrectiveActionFormValues();
						newCorrectiveAction.nonConformance = tempValue[qIndex].question;
						values.correctiveActions.push(newCorrectiveAction);
						continue;
					}
				// if the current value is not 'N', look for object in correctiveActions with matching key to remove
				// i.e. if someone changes a No answer to Yes or N/A, we need to remove the CA
				} else {
					// Ensures we skip over fields like ID that don't have the values we need to be checking
					if (values[property] === "Y" || values[property] === "N/A"){
						let tempValue = fsaQuestions[index].questions;
						let qIndex = tempValue.findIndex((question: IQuestion) => question.key === parseInt(answerNumber));
						if (values.correctiveActions.some((el: ICorrectiveAction) => el.nonConformance === tempValue[qIndex].question)) {
							let index = values.correctiveActions.findIndex((el: ICorrectiveAction) => el.nonConformance === tempValue[qIndex].question);
							if (index > -1) {
								let temp_array = values.correctiveActions;
								temp_array.splice(index, 1);
								values.correctiveActions = temp_array;
							}
						}
					}
				}
			}
		} else {
			// console.log("None exist, dont need to worry about checking for pre-existing");
			// console.log("Look for any N answers");
			for (const property in values) {
				let questionSet = property.replaceAll(/[0-9]/g, '');
				let answerNumber = property.replaceAll(/[A-Za-z]/g, '');
				let index = fsaQuestions.findIndex((questionGroup: IfsaQuestionGroup) => questionGroup.propName === questionSet);
				
				if (values[property] === "N") {
					let newCorrectiveAction = new CorrectiveActionFormValues();
					//console.log('index ' + index);	//index: -1
					let tempValue = fsaQuestions[index].questions
					let qIndex = tempValue.findIndex((question: IQuestion) => question.key === parseInt(answerNumber));
					newCorrectiveAction.nonConformance = tempValue[qIndex].question;
					values.correctiveActions.push(newCorrectiveAction);
				}
			}
		}
		return;
	}

	const handleSectionChange = (values: FieldSafetyAuditFormValues, curVal: string) => {
		//console.log(values);
		
		let propName: string = curVal.split('-')[0];
		let ans = curVal.split('-')[1];

		let newFsa: IFieldSafetyAudit = JSON.parse(
			JSON.stringify(values)
		);

		// TODO: pain
		// Object.keys(newFsa).filter((x:string) => x.includes(propName)).forEach((x:any) => newFsa[x] = ans);

		if (propName === 'administrative') {
			newFsa.administrative1 = ans;
			newFsa.administrative2 = ans;
			newFsa.administrative3 = ans;
			newFsa.administrative4 = ans;
			newFsa.administrative5 = ans;
			newFsa.administrative6 = ans;
			newFsa.administrative7 = ans;
			newFsa.administrative8 = ans;
		}
		else if (propName === 'electrical') {
			newFsa.electrical1 = ans;
			newFsa.electrical2 = ans;
			newFsa.electrical3 = ans;
			newFsa.electrical4 = ans;
			newFsa.electrical5 = ans;
			newFsa.electrical6 = ans;
			newFsa.electrical7 = ans;
		}
		else if (propName === 'emergencies') {
			newFsa.emergencies1 = ans;
			newFsa.emergencies2 = ans;
			newFsa.emergencies3 = ans;
			newFsa.emergencies4 = ans;
			newFsa.emergencies5 = ans
		}
		else if (propName === 'excavationTrenching') {
			newFsa.excavationTrenching1 = ans;
			newFsa.excavationTrenching2 = ans;
			newFsa.excavationTrenching3 = ans;
			newFsa.excavationTrenching4 = ans;
			newFsa.excavationTrenching5 = ans
			newFsa.excavationTrenching6 = ans
			newFsa.excavationTrenching7 = ans
			newFsa.excavationTrenching8 = ans
			newFsa.excavationTrenching9 = ans
		}
		else if (propName === 'fallProtection') {
			newFsa.fallProtection1 = ans;
			newFsa.fallProtection2 = ans;
			newFsa.fallProtection3 = ans;
			newFsa.fallProtection4 = ans;
			newFsa.fallProtection5 = ans
			newFsa.fallProtection6 = ans
			newFsa.fallProtection7 = ans
			newFsa.fallProtection8 = ans
			newFsa.fallProtection9 = ans
			newFsa.fallProtection10 = ans
			newFsa.fallProtection11 = ans
		}
		else if (propName === 'firePrevention') {
			newFsa.firePrevention1 = ans;
			newFsa.firePrevention2 = ans;
			newFsa.firePrevention3 = ans;
			newFsa.firePrevention4 = ans;
		}
		else if (propName === 'general') {
			newFsa.general1 = ans;
			newFsa.general2 = ans;
			newFsa.general3 = ans;
			newFsa.general4 = ans;
			newFsa.general5 = ans;
			newFsa.general6 = ans;
			newFsa.general7 = ans;
		}
		else if (propName === 'ladders') {
			newFsa.ladders1 = ans;
			newFsa.ladders2 = ans;
			newFsa.ladders3 = ans;
			newFsa.ladders4 = ans;
		}
		else if (propName === 'ppe') {
			newFsa.ppe1 = ans;
			newFsa.ppe2 = ans;
			newFsa.ppe3 = ans;
			newFsa.ppe4 = ans;
			newFsa.ppe5 = ans;
			newFsa.ppe6 = ans;
		}
		else if (propName === 'radiation') {
			newFsa.radiation1 = ans;
			newFsa.radiation2 = ans;
			newFsa.radiation3 = ans;
			newFsa.radiation4 = ans;
			newFsa.radiation5 = ans;
		}
		else if (propName === 'roadway') {
			newFsa.roadway1 = ans;
			newFsa.roadway2 = ans;
			newFsa.roadway3 = ans;
			newFsa.roadway4 = ans;
		}
		else if (propName === 'scaffolding') {
			newFsa.scaffolding1 = ans;
			newFsa.scaffolding2 = ans;
			newFsa.scaffolding3 = ans;
			newFsa.scaffolding4 = ans;
			newFsa.scaffolding5 = ans;
			newFsa.scaffolding6 = ans;
			newFsa.scaffolding7 = ans;
			newFsa.scaffolding8 = ans;
		}
		else if (propName === 'tools') {
			newFsa.tools1 = ans;
			newFsa.tools2 = ans;
			newFsa.tools3 = ans;
			newFsa.tools4 = ans;
			newFsa.tools5 = ans;
			newFsa.tools6 = ans;
			newFsa.tools7 = ans;
			newFsa.tools8 = ans;
			newFsa.tools9 = ans;
		}
		else if (propName === 'water') {
			newFsa.water1 = ans;
			newFsa.water2 = ans;
			newFsa.water3 = ans;
			newFsa.water4 = ans;
		}
		
		setFieldSafetyAudit(newFsa);
		return;
	}

	const handleFinalFormSubmit = (values: FieldSafetyAuditFormValues) => {
		const payload = new FormData();
		
		//#region setting payload fields
		payload.set('safetyChecklist_Id', values.safetyChecklist_id);
		payload.set('discipline', values.discipline_id);
		// payload.set('status', '');
		// payload.set('sslApproval', '');
		
		payload.set('administrative1', values.administrative1);
		payload.set('administrative2', values.administrative2);
		payload.set('administrative3', values.administrative3);
		payload.set('administrative4', values.administrative4);
		payload.set('administrative5', values.administrative5);
		payload.set('administrative6', values.administrative6);
		payload.set('administrative7', values.administrative7);
		payload.set('administrative8', values.administrative8);
		payload.set('electrical1', values.electrical1);
		payload.set('electrical2', values.electrical2);
		payload.set('electrical3', values.electrical3);
		payload.set('electrical4', values.electrical4);
		payload.set('electrical5', values.electrical5);
		payload.set('electrical6', values.electrical6);
		payload.set('electrical7', values.electrical7);
		payload.set('emergencies1', values.emergencies1);
		payload.set('emergencies2', values.emergencies2);
		payload.set('emergencies3', values.emergencies3);
		payload.set('emergencies4', values.emergencies4);
		payload.set('emergencies5', values.emergencies5);
		payload.set('excavationTrenching1', values.excavationTrenching1);
		payload.set('excavationTrenching2', values.excavationTrenching2);
		payload.set('excavationTrenching3', values.excavationTrenching3);
		payload.set('excavationTrenching4', values.excavationTrenching4);
		payload.set('excavationTrenching5', values.excavationTrenching5);
		payload.set('excavationTrenching6', values.excavationTrenching6);
		payload.set('excavationTrenching7', values.excavationTrenching7);
		payload.set('excavationTrenching8', values.excavationTrenching8);
		payload.set('excavationTrenching9', values.excavationTrenching9);
		payload.set('fallProtection1', values.fallProtection1);
		payload.set('fallProtection2', values.fallProtection2);
		payload.set('fallProtection3', values.fallProtection3);
		payload.set('fallProtection4', values.fallProtection4);
		payload.set('fallProtection5', values.fallProtection5);
		payload.set('fallProtection6', values.fallProtection6);
		payload.set('fallProtection7', values.fallProtection7);
		payload.set('fallProtection8', values.fallProtection8);
		payload.set('fallProtection9', values.fallProtection9);
		payload.set('fallProtection10', values.fallProtection10);
		payload.set('fallProtection11', values.fallProtection11);
		payload.set('firePrevention1', values.firePrevention1);
		payload.set('firePrevention2', values.firePrevention2);
		payload.set('firePrevention3', values.firePrevention3);
		payload.set('firePrevention4', values.firePrevention4);
		payload.set('general1', values.general1);
		payload.set('general2', values.general2);
		payload.set('general3', values.general3);
		payload.set('general4', values.general4);
		payload.set('general5', values.general5);
		payload.set('general6', values.general6);
		payload.set('general7', values.general7);
		payload.set('ladders1', values.ladders1);
		payload.set('ladders2', values.ladders2);
		payload.set('ladders3', values.ladders3);
		payload.set('ladders4', values.ladders4);
		payload.set('ppe1', values.ppe1);
		payload.set('ppe2', values.ppe2);
		payload.set('ppe3', values.ppe3);
		payload.set('ppe4', values.ppe4);
		payload.set('ppe5', values.ppe5);
		payload.set('ppe6', values.ppe6);
		payload.set('ppeInUse', values.ppeInUse);
		payload.set('radiation1', values.radiation1);
		payload.set('radiation2', values.radiation2);
		payload.set('radiation3', values.radiation3);
		payload.set('radiation4', values.radiation4);
		payload.set('radiation5', values.radiation5);
		payload.set('roadway1', values.roadway1);
		payload.set('roadway2', values.roadway2);
		payload.set('roadway3', values.roadway3);
		payload.set('roadway4', values.roadway4);
		payload.set('scaffolding1', values.scaffolding1);
		payload.set('scaffolding2', values.scaffolding2);
		payload.set('scaffolding3', values.scaffolding3);
		payload.set('scaffolding4', values.scaffolding4);
		payload.set('scaffolding5', values.scaffolding5);
		payload.set('scaffolding6', values.scaffolding6);
		payload.set('scaffolding7', values.scaffolding7);
		payload.set('scaffolding8', values.scaffolding8);
		payload.set('tools1', values.tools1);
		payload.set('tools2', values.tools2);
		payload.set('tools3', values.tools3);
		payload.set('tools4', values.tools4);
		payload.set('tools5', values.tools5);
		payload.set('tools6', values.tools6);
		payload.set('tools7', values.tools7);
		payload.set('tools8', values.tools8);
		payload.set('tools9', values.tools9);
		payload.set('water1', values.water1);
		payload.set('water2', values.water2);
		payload.set('water3', values.water3);
		payload.set('water4', values.water4);
		
		if(values.auditeeEmails && values.auditeeEmails !== undefined)
			values.auditeeEmails.forEach(i => payload.append('auditeeEmails', i));
		
		if(values.otherItemsObject && values.otherItemsObject !== undefined)
			values.otherItemsObject.forEach(i => payload.append('otherItems', i.description));
		
		// TODO Fix Date fields being 'null' as a string on submit
		if(values.correctiveActions && values.correctiveActions !== undefined)
			values.correctiveActions.map(ca => new ServerboundNewCorrectiveAction(ca)).forEach((sbnca,i) => Object.entries(sbnca).forEach(([k,v]) => payload.set(`correctiveActions[${i}].${k}`, v)));
		
		if(values.fileArray && values.fileArray !== undefined)
			values.fileArray.forEach(i => payload.append('newFiles', i));
		
		//#endregion
		
		// let fieldSafetyAudit: IFieldSafetyAudit = JSON.parse(
		// 	JSON.stringify(values)
		// );
		// console.log(fieldSafetyAudit);
		//console.log(JSON.parse(JSON.stringify(Array.from(payload.entries()))));
		if (!values.id) {
			createFieldSafetyAudit(payload);
		} else {
			editFieldSafetyAudit(values.id, payload);
		}
	};

	return (
		<>
			<PageTitle title="Field Safety Audit Form" />

			<Segment clearing>
				<Step.Group ordered fluid size='mini'>
					<Step completed={step > 1} active={step === 1}>
						<Step.Content>
						<Step.Title>Step 1</Step.Title>
						</Step.Content>
					</Step>

					<Step completed={step > 2} active={step === 2}>
						<Step.Content>
						<Step.Title>Step 2</Step.Title>
						</Step.Content>
					</Step>		

					<Step completed={step > 3} active={step === 3}>
						<Step.Content>
						<Step.Title>Step 3</Step.Title>
						</Step.Content>
					</Step>		

					<Step completed={step > 4} active={step === 4}>
						<Step.Content>
						<Step.Title>Step 4</Step.Title>
						</Step.Content>
					</Step>		
				</Step.Group>
				<FinalForm
					validate={validate}
					initialValues={fieldSafetyAudit}
					onSubmit={handleFinalFormSubmit}
					mutators={{ ...arrayMutators }}
					loading={loading}
					render={({
						handleSubmit,
						form: {
							mutators: { push, pop },
						},
						invalid,
						pristine,
						values,
					}) => (
						<Form onSubmit={handleSubmit} loading={loading}>
							{/* <Button
								content="Previous Step"
								onClick={() => prevStep()}
								type="button"
								disabled={step < 2}
							/>
							<Button
								content="Next Step"`
								onClick={() => nextStep(values)}
								type="button"
								disabled={step === 4}
							/> */}

							<Button
								disabled={loading}
								color='yellow'
								type="button"
								content="Cancel"
								onClick={()=>openModal(<ConfirmCancelForm />)}
							/>	
							
							<StepButtons
								// isValid={() => isStepValid(values)}
								isValid={() => null}
								step={step}
								prevStep={prevStep}
								nextStep={() => nextStep(values)}
								stepmax={4}
								scrollToTop
							/>

							<RenderIf condition={step === 1}>
								<Segment>
									<Form.Group widths="equal">
										<div className="field">
										<label>Safety Checklist</label>
										{/* TODO this doesn't render on edit. what loads it in the validator? */}
										<p>{safetyChecklist.projectNumber}: {safetyChecklist.disciplineName}</p>
										</div>

										<Field
											name="auditeeEmails"
											label="Auditees"
											placeholder="Select Auditees..."
											search={true}
											defaultValue={fieldSafetyAudit.auditeeEmails}
											component={MultipleSelectInput}
											options={users?.map((user) => ({
												key: user.aoid,
												value: user.email,
												text: user.formattedName,
											}))}
										/>
									</Form.Group>
								</Segment>
							</RenderIf>

							<RenderIf condition={step === 2}>
								<Segment>
									{/* dynamically load fsaQuestions */}
									{fsaQuestions.map((group) => (
										<Segment key={group.header}>
											<Header>{group.header}
												{/*  answers for entire section */}
												<Button.Group floated="right">
													<Button
														content='Yes'
														onClick={() => handleSectionChange(values, group.propName + '-Y')}
														type='button'
														/>
													<Button
														content='No'
														onClick={() => handleSectionChange(values, group.propName + '-N')}
														type='button'
														/>
													<Button
														content='N/A'
														onClick={() => handleSectionChange(values, group.propName + '-N/A')}
														type='button'
													/>
												</Button.Group>
											</Header>
											<Grid className="fsaContainer">
												{group.questions.map((question) => (
													<Grid.Row key={"" + group.header + question.key} className='fsaRow'>
														<Grid.Column>
															<div className="fsaQuestions">
																{question.question}:
															</div>

															<div className="fsaAnswers">
																<label>
																	<Field
																		name={group.propName + question.key}
																		component="input"
																		type="radio"
																		value="Y"
																		label="Y"
																	/>{" "}
																	Yes
																</label>

																<label>
																	<Field
																		name={group.propName + question.key}
																		component="input"
																		type="radio"
																		value="N"
																	/>{" "}
																	No
																</label>

																<label>
																	<Field
																		name={group.propName + question.key}
																		component="input"
																		type="radio"
																		value="N/A"
																	/>{" "}
																	N/A
																</label>
															</div>
														</Grid.Column>
													</Grid.Row>
												))}
											</Grid>

											{group.header === "Personal Protection Equipment" && (
												<>
													<br />
													<div>Briefly describe PPE in use:</div>
													<Field
														name="ppeInUse"
														rows={2}
														value={fieldSafetyAudit.ppeInUse}
														component={TextAreaInput}
													/>
												</>
											)}
										</Segment>
									))}
								</Segment>
							</RenderIf>

							<RenderIf condition={step === 3}>
								<Segment>
									<Header>Comment/Corrective Actions</Header>
									{values.correctiveActions && values.correctiveActions.length > 0 ? (
										<FieldArray name="correctiveActions">
											{({ fields }) =>
												fields.map((action_field_name, i) => (
													<Segment key={action_field_name}>
														<Header as="h4">Non-Conformance:{" "}{values.correctiveActions[i]?.nonConformance}</Header>
														<Form.Group widths="equal">
															<Field
																component={TextAreaInput}
																placeholder="Comments"
																name={`${action_field_name}.comments`}
															/>
														</Form.Group>
													</Segment>
												))
											}
										</FieldArray>
									) : (
										"No Non-Conformances Reported"
									)}
								</Segment>
							</RenderIf>

							<RenderIf condition={step === 4}>
								<Segment>
									<Header as='h3'>Other Items</Header>
									<FieldArray name='otherItemsObject'>
										{({ fields }) => fields.map((other_item_field_name, i) =>
										<Segment key={other_item_field_name}>
											<Header as='h4'>Item {i + 1}</Header>
											<Form.Group widths='equal'>
												<Field component={TextInput} placeholder='Description...' name={`${other_item_field_name}.description`} />
											</Form.Group>
											<div>
											{values.otherItemsObject.length !== 1 && <Button basic color='red' onClick={() => fields.remove(i)} type='button'>Remove</Button>}
											{values.otherItemsObject.length - 1 === i && <Button basic color='green' onClick={() => push('otherItemsObject', new OtherItemFormValues())} type='button'>Add</Button>}
											</div>
										</Segment>
										)}
									</FieldArray>
								</Segment>
								<Segment>
									<Form.Group widths="equal">
										<FieldFileDropZone name="fileArray" accept={[
											"image/*",
											"application/vnd.openxmlformats-officedocument.wordprocessingml.document",
											"application/msword",
											"application/pdf"
										].join(',')} maxFiles={6} />
									</Form.Group>
								</Segment>
							</RenderIf>
					
					
							<RenderIf condition={step === 4}>
								<Button
									loading={submitting}
									//disabled={loading || invalid || pristine}
									floated="right"
									positive
									type="submit"
									content="Submit"
								/>
							</RenderIf>
							<Button
								disabled={loading}
								color='yellow'
								type="button"
								content="Cancel"
								onClick={()=>openModal(<ConfirmCancelForm />)}
							/>	

							<StepButtons
								// isValid={() => isStepValid(values)}
								isValid={() => null}
								step={step}
								prevStep={prevStep}
								nextStep={() => nextStep(values)}
								stepmax={4}
								scrollToTop
							/>
						</Form>
					)}
				/>
			</Segment>
		</>
	);
};

export default observer(FieldSafetyAuditForm);
