import { observable, action, runInAction, computed } from "mobx";
import agent from "../api/agent";
import { IProject } from "../models/project";
import { ISafetyChecklist } from "../models/safetyChecklist";
import { ISituation } from "../models/situation";
import { IHazard } from "../models/hazard";
import { IDiscipline, DisciplineFormValues } from "../models/discipline";
import { IUser } from "../models/user";
import { ISOP } from "../models/sop";
import { RootStore } from "./rootStore";
import { IFallHaz, IServerBoundFallHaz } from "../../app/models/fallHaz";
import { IServerBoundSiteSafety, ISiteSafetyBriefing } from "../../app/models/siteSafetyBriefing";

// import { SyntheticEvent } from "react";

import { history } from "../..";
import { toast } from "react-toastify";
import { IChemicalList } from "../models/chemicalInv";
import { IServerBoundTrafficControl, ITrafficControl } from "../models/trafficControl";
import { IConfinedSpaceA, IServerBoundConfinedSpaceA } from "../models/confinedSpaceA";
import { IServerBoundExcavationChecklist } from "../models/excavationChecklist";
import { IAerialLiftChecklist, IServerBoundAerialLiftChecklist } from "../models/aerialLiftChecklist";
import { IComment, IServerBoundComment } from "../models/comment";
import { localizeDateTime } from "../common/util/util";

const PAGE_SIZE = 8;

export default class SafetyChecklistStore {

  rootStore: RootStore;
  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
  }

  @observable safetyChecklistRegistry = new Map<string, ISafetyChecklist>();

  @observable safetyChecklist: ISafetyChecklist | null = null;
  @observable loadingInitial = true;
  @observable submitting = false;
  @observable target = "";
  @observable discipline: IDiscipline = new DisciplineFormValues();

  @observable siteSafetyBriefing: ISiteSafetyBriefing | null = null;
  @observable confinedSpaceA: IConfinedSpaceA| null = null;
  @observable aerialLift: IAerialLiftChecklist | null = null;
  @observable fallHazardAssessment: IFallHaz | null = null;
  @observable trafficControlAssessment: ITrafficControl | null = null;
  //@observable siteSafetyBriefing: ISiteSafetyBriefing | null = null;

  @observable safetyChecklistCount = 0;
  @observable page = 0;
  @observable predicate = new Map<string, string | number | boolean>();

  @observable projects: IProject[] = [];
  @observable users: IUser[] = [];
  @observable siteSafetyLeaders: IUser[] = []
  @observable disciplines: IDiscipline[] = [];
  @observable situations: ISituation[] = [];

  @observable outdoorsGeneral: ISituation[] = [];
  @observable activeRoadways: ISituation[] = [];
  @observable chemical: ISituation[] = [];
  @observable confinedSpaces: ISituation[] = [];
  @observable construction: ISituation[] = [];
  @observable pipeline: ISituation[] = [];
  @observable railroad: ISituation[] = [];
  @observable scaffolds: ISituation[] = [];
  @observable structuresBuildings: ISituation[] = [];
  @observable trenching: ISituation[] = [];
  @observable waste: ISituation[] = [];
  @observable wastewater: ISituation[] = [];
  @observable waterfront: ISituation[] = [];
  @observable associatedHazards: IHazard[] = [];

  @observable sops: ISOP[] = [];

  @action redefineDatesWithTimezones = (safetyChecklist: ISafetyChecklist) => {
    safetyChecklist.created = localizeDateTime(new Date(safetyChecklist.created));
    if(safetyChecklist.lastModified){
      safetyChecklist.lastModified = localizeDateTime(new Date(safetyChecklist.lastModified));
    }
    safetyChecklist.estimatedStartDate = localizeDateTime(new Date(safetyChecklist.estimatedStartDate));
    safetyChecklist.estimatedCompletionDate = localizeDateTime(new Date(safetyChecklist.estimatedCompletionDate));

    if (safetyChecklist.notifications) {
      for (let i = 0; i < safetyChecklist.notifications.length; i++) {
        safetyChecklist.notifications[i].created = localizeDateTime(new Date(safetyChecklist.notifications[i].created));
        if (safetyChecklist.notifications[i].lastAcknowledged !== null) {
          safetyChecklist.notifications[i].lastAcknowledged = localizeDateTime(new Date(safetyChecklist.notifications[i].lastAcknowledged));
        }
        if (safetyChecklist.notifications[i].updated !== null) {
          safetyChecklist.notifications[i].updated = localizeDateTime(new Date(safetyChecklist.notifications[i].updated));
        }
      } 
    }

    if(safetyChecklist.fallHazardAssessments){
      for(let i=0; i < safetyChecklist.fallHazardAssessments.length; i++){
        safetyChecklist.fallHazardAssessments[i].createDate = localizeDateTime(new Date(safetyChecklist.fallHazardAssessments[i].createDate));
      }
    }

    if(safetyChecklist.trafficControlAssessments){
      for(let i=0; i < safetyChecklist.trafficControlAssessments.length; i++){
        safetyChecklist.trafficControlAssessments[i].createDate = localizeDateTime(new Date(safetyChecklist.trafficControlAssessments[i].createDate));
      }
    }

    if(safetyChecklist.confinedSpaceAssessments){
      for (let i=0; i< safetyChecklist.confinedSpaceAssessments.length; i++){
        safetyChecklist.confinedSpaceAssessments[i].createDate = localizeDateTime(new Date(safetyChecklist.confinedSpaceAssessments[i].createDate));
      }
    }

    if(safetyChecklist.aerialLifts){
      for (let i=0; i< safetyChecklist.aerialLifts.length; i++){
        safetyChecklist.aerialLifts[i].createDate = localizeDateTime(new Date(safetyChecklist.aerialLifts[i].createDate));
      }
    }

    if(safetyChecklist.siteSafetyBriefings){
      for (let i=0; i< safetyChecklist.siteSafetyBriefings.length; i++){
        safetyChecklist.siteSafetyBriefings[i].createDate = localizeDateTime(new Date(safetyChecklist.siteSafetyBriefings[i].createDate));
      }
    }
  }

  @action checkUndefinedOrNullText = (safetyChecklist: ISafetyChecklist) => {
    if(safetyChecklist.emergencySignal === 'null' || safetyChecklist.emergencySignal === 'undefined')
      safetyChecklist.emergencySignal = '';
    if(safetyChecklist.evacRouteAndMusterPoint === 'null' || safetyChecklist.evacRouteAndMusterPoint === 'undefined')
      safetyChecklist.evacRouteAndMusterPoint = '';
  }

  @action setDisciplineField = (safetyChecklist: ISafetyChecklist) =>{
    if(safetyChecklist.discipline){
      safetyChecklist.discipline_id = safetyChecklist.discipline.id;
    }
  }

  @action clearId = (safetyChecklist: ISafetyChecklist) => {
    safetyChecklist.id = '';
  }

  // if we call loadSafetyInitial first, we know the store will already be set
  @action setHazards = (safetyChecklist: ISafetyChecklist, newChecklistInfo: any) => {
    var sorted = false;
    if(safetyChecklist.hazards !== null && safetyChecklist.hazards !== undefined){
      safetyChecklist.hazards!.forEach(hazard => {
        sorted = false;

        // handle additional hazards before standard ones
        if(!sorted){
          newChecklistInfo.associatedHazards.some((a_hazard: IHazard) => {
            if(hazard.id === a_hazard.id){
              if(safetyChecklist.additionalHazards_ids === undefined){
                safetyChecklist.additionalHazards_ids = [hazard.id,];
              } else {
                safetyChecklist.additionalHazards_ids.push(hazard.id);
              }
            }
          })
        }

        if(!sorted){
          newChecklistInfo.outdoorsGeneral.some((situation: ISituation) => {
            if(hazard.id === situation.id){
              if(safetyChecklist.outdoorsGeneral_ids === undefined){
                safetyChecklist.outdoorsGeneral_ids = [hazard.id,];
              } else {
                safetyChecklist.outdoorsGeneral_ids.push(hazard.id);
              }
              sorted = true;
            }
          })
        }

        if(!sorted){
          newChecklistInfo.activeRoadways.some((situation: ISituation) => {
            if(hazard.id === situation.id){
              if(safetyChecklist.activeRoadways_ids === undefined){
                safetyChecklist.activeRoadways_ids = [hazard.id,];
              } else {
                safetyChecklist.activeRoadways_ids.push(hazard.id);
              }
              sorted = true;
            }
          })
        }

        if(!sorted){
          newChecklistInfo.chemical.some((situation: ISituation) => {
            if(hazard.id === situation.id){
              if(safetyChecklist.chemical_ids === undefined){
                safetyChecklist.chemical_ids = [hazard.id,];
              } else {
                safetyChecklist.chemical_ids.push(hazard.id);
              }
              sorted = true;
            }
          })
        }

        if(!sorted){
          newChecklistInfo.confinedSpaces.some((situation: ISituation) => {
            if(hazard.id === situation.id){
              if(safetyChecklist.confinedSpaces_ids === undefined){
                safetyChecklist.confinedSpaces_ids = [hazard.id,];
              } else {
                safetyChecklist.confinedSpaces_ids.push(hazard.id);
              }
              sorted = true;
            }
          })
        }

        if(!sorted){
          newChecklistInfo.construction.some((situation: ISituation) => {
            if(hazard.id === situation.id){
              if(safetyChecklist.construction_ids === undefined){
                safetyChecklist.construction_ids = [hazard.id,];
              } else {
                safetyChecklist.construction_ids.push(hazard.id);
              }
              sorted = true;
            }
          })
        }

        if(!sorted){
          newChecklistInfo.pipeline.some((situation: ISituation) => {
            if(hazard.id === situation.id){
              if(safetyChecklist.pipeline_ids === undefined){
                safetyChecklist.pipeline_ids = [hazard.id,];
              } else {
                safetyChecklist.pipeline_ids.push(hazard.id);
              }
              sorted = true;
            }
          })
        }

        if(!sorted){
          newChecklistInfo.railroad.some((situation: ISituation) => {
            if(hazard.id === situation.id){
              if(safetyChecklist.railroad_ids === undefined){
                safetyChecklist.railroad_ids = [hazard.id,];
              } else {
                safetyChecklist.railroad_ids.push(hazard.id);
              }
              sorted = true;
            }
          })
        }

        if(!sorted){
          newChecklistInfo.scaffolds.some((situation: ISituation) => {
            if(hazard.id === situation.id){
              if(safetyChecklist.scaffolds_ids === undefined){
                safetyChecklist.scaffolds_ids = [hazard.id,];
              } else {
                safetyChecklist.scaffolds_ids.push(hazard.id);
              }
              sorted = true;
            }
          })
        }

        if(!sorted){
          newChecklistInfo.structuresBuildings.some((situation: ISituation) => {
            if(hazard.id === situation.id){
              if(safetyChecklist.structuresBuildings_ids === undefined){
                safetyChecklist.structuresBuildings_ids = [hazard.id,];
              } else {
                safetyChecklist.structuresBuildings_ids.push(hazard.id);
              }
              sorted = true;
            }
          })
        }

        if(!sorted){
          newChecklistInfo.trenching.some((situation: ISituation) => {
            if(hazard.id === situation.id){
              if(safetyChecklist.trenching_ids === undefined){
                safetyChecklist.trenching_ids = [hazard.id,];
              } else {
                safetyChecklist.trenching_ids.push(hazard.id);
              }
              sorted = true;
            }
          })
        }

        if(!sorted){
          newChecklistInfo.waste.some((situation: ISituation) => {
            if(hazard.id === situation.id){
              if(safetyChecklist.waste_ids === undefined){
                safetyChecklist.waste_ids = [hazard.id,];
              } else {
                safetyChecklist.waste_ids.push(hazard.id);
              }
              sorted = true;
            }
          })
        }

        if(!sorted){
          newChecklistInfo.wastewater.some((situation: ISituation) => {
            if(hazard.id === situation.id){
              if(safetyChecklist.wastewater_ids === undefined){
                safetyChecklist.wastewater_ids = [hazard.id,];
              } else {
                safetyChecklist.wastewater_ids.push(hazard.id);
              }
              sorted = true;
            }
          })
        }

        if(!sorted){
          newChecklistInfo.waterfront.some((situation: ISituation) => {
            if(hazard.id === situation.id){
              if(safetyChecklist.waterfront_ids === undefined){
                safetyChecklist.waterfront_ids = [hazard.id,];
              } else {
                safetyChecklist.waterfront_ids.push(hazard.id);
              }
              sorted = true;
            }
          })
        }
      })
      
    }
    return;
  }

  /*************************************
   * begin pagination
   *************************************/
  
   @action setCount = (count: number) => {
    this.safetyChecklistCount = count;
  };

  @action setPage = (page: number) => {
    this.page = page;
  }
  
  /*************************************
   * begin params
   *************************************/
  getPredicate = () => {
    return this.predicate;
  };

  @computed get axiosParams() {
    const params = new URLSearchParams();
    params.append("pageNumber", `${this.page}`);
    params.append("pageSize", String(PAGE_SIZE));
    
    this.predicate.forEach((value: string | number | boolean, key: string) => {
      params.append(key, value.toString());
    });
    
    return params;
  }

  @action setPredicate = (
    predicate: string,
    value: string | number | boolean
  ) => {
    if (predicate !== "all") {
      this.predicate.set(predicate, value);
    }
  };

  @action clearPredicate = () => {
    this.predicate.clear();
  };

  @action removePredicate = (key: string) => {
    this.predicate.delete(key);
  };

  @computed get safetyChecklistsByDefault() {
    return Array.from(this.safetyChecklistRegistry.values()).sort(
      (a, b) => b.created.getTime() - a.created.getTime()
    ); // sort in descending order
  }

  @computed get totalPages() {
    return Math.ceil(this.safetyChecklistCount / PAGE_SIZE);
  }

  @action clearSafetyChecklistRegistry = () => {
    this.safetyChecklistRegistry.clear();
    this.page = 0;
  };


  /*************************************
  *
  *************************************/

  @computed get safetyChecklistsByDate() {
    return this.groupSafetyChecklistsByDate(
      Array.from(this.safetyChecklistRegistry.values())
    );
  }

  /*************************************
  *
  *************************************/

  groupSafetyChecklistsByDate(safetyChecklists: ISafetyChecklist[]) {
    const sortedSafetyChecklists = safetyChecklists.sort(
      (a, b) => b.created.getTime() - a.created.getTime()
    );

    return Object.entries(
      sortedSafetyChecklists.reduce((reports, report) => {
        const date = report.created.toISOString().split('T')[0];
        reports[date] = reports[date] ? [...reports[date], report] : [report];
        return reports;
      }, {} as { [key: string]: ISafetyChecklist[] })
    );
  }


  projectDropdownResults(params: any, projects: IProject[]) {
    if (params) {
      return Array.from(projects.values()).filter(
        project => project.title.includes(params)
      );

    } else {
      return Array.from(projects.values()).sort(
        (a, b) => a.title.localeCompare(b.title)
      ).slice(0, 50);
    }
  }

  /*************************************
   *
   *************************************/
  @action loadDisciplines = async () => {
    try {
      const response = await agent.Discipline.safetyList();
      runInAction("loading safety disciplines", () => {
        this.disciplines = response;
        //this.loadingInitial = false;
      });
    } catch (error) {
      runInAction("load safety disciplines error", () => {
        this.loadingInitial = false;
      });
      console.log(error);
    }
  }

  @action loadSafetyInitial = async () => {
    this.loadingInitial = true;
    try {
      const response = await agent.SafetyChecklist.newChecklistInfo();

      runInAction("loading new safety checklist info", () => {
        this.redefineDatesWithTimezones(response);
        this.users = response.usersToReturn;
        this.siteSafetyLeaders = response.sslsToReturn;
        this.loadingInitial = false;
        this.activeRoadways = response.activeRoadways;
        this.outdoorsGeneral = response.outdoorsGeneral;
        this.chemical = response.chemical;
        this.confinedSpaces = response.confinedSpaces;
        this.construction = response.construction;
        this.pipeline = response.pipeline;
        this.railroad = response.railroad;
        this.scaffolds = response.scaffolds;
        this.structuresBuildings = response.structuresBuildings;
        this.trenching = response.trenching;
        this.waste = response.waste;
        this.wastewater = response.wastewater;
        this.waterfront = response.waterfront;
        this.associatedHazards = response.associatedHazards;
        // response.associatedHazards.forEach((hazard: IHazard) => {
        //   if(hazard.associatedJobNumber? === )
        //   this.associatedHazards.push()
        // });
        this.sops = response.sopsToReturn;
        this.disciplines = response.disciplinesToReturn;
        this.loadingInitial = false;
      });
    } catch (error) {
      runInAction("load new safety checklist info error", () => {
        this.loadingInitial = false;
      });
      console.log(error);
    }
  };


  @action loadSafetyChecklist = async (id: string) => {
    this.loadingInitial = true;
    try {
      let safetyChecklist = await agent.SafetyChecklist.details(id);
      const newInfo = await agent.SafetyChecklist.newChecklistInfo();
      runInAction('load existing safety checklist', () => {
        if(safetyChecklist){
          this.loadingInitial = true;
          this.redefineDatesWithTimezones(safetyChecklist);
          this.checkUndefinedOrNullText(safetyChecklist);
          this.setDisciplineField(safetyChecklist);
          this.setHazards(safetyChecklist, newInfo);
          this.safetyChecklist = safetyChecklist;
          this.safetyChecklistRegistry.set(safetyChecklist.id, safetyChecklist);
        }
        this.loadingInitial = false;
      });
      return safetyChecklist;
    } catch (error) {
      runInAction('get safety checklist error', () => {
        this.loadingInitial = false;
      });
      
      console.log(error);
    }
  };

  @action loadSafetyChecklistForUpdate = async (id: string) => {
    this.loadingInitial = true;
    try {
      let safetyChecklist = await agent.SafetyChecklist.detailsForUpdate(id);
      const newInfo = await agent.SafetyChecklist.newChecklistInfo();
      runInAction('load existing safety checklist', () => {
        if(safetyChecklist){
          this.loadingInitial = true;
          this.redefineDatesWithTimezones(safetyChecklist);
          this.checkUndefinedOrNullText(safetyChecklist);
          this.setDisciplineField(safetyChecklist);
          this.setHazards(safetyChecklist, newInfo);
          this.safetyChecklist = safetyChecklist;
          this.safetyChecklistRegistry.set(safetyChecklist.id, safetyChecklist);
        }
        this.loadingInitial = false;
      });
      return safetyChecklist;
    } catch (error) {
      runInAction('get safety checklist error', () => {
        this.loadingInitial = false;
      });
      
      console.log(error);
    }
  };

  @action loadSafetyChecklistTemplate = async (id: string) => {
    this.loadingInitial = true;
    try {
      let safetyChecklist = await agent.SafetyChecklist.detailsForUpdate(id);
      const newInfo = await agent.SafetyChecklist.newChecklistInfo();
      runInAction('load existing safety checklist', () => {
        if(safetyChecklist){
          this.loadingInitial = true;
          this.redefineDatesWithTimezones(safetyChecklist);
          this.checkUndefinedOrNullText(safetyChecklist);
          this.setDisciplineField(safetyChecklist);
          this.setHazards(safetyChecklist, newInfo);
          this.clearId(safetyChecklist);
          this.safetyChecklist = safetyChecklist;
          this.safetyChecklistRegistry.set(safetyChecklist.id, safetyChecklist);
        }
        this.loadingInitial = false;
      });
      return safetyChecklist;
    } catch (error) {
      runInAction('get safety checklist error', () => {
        this.loadingInitial = false;
      });
      
      console.log(error);
    }
  };

  @action loadSafetyChecklistDetail = async (id: string) => {
    this.loadingInitial = true;
    try {
      let safetyChecklist = await agent.SafetyChecklist.details(id);
      runInAction('load existing safety checklist', () => {
        if(safetyChecklist){
          this.redefineDatesWithTimezones(safetyChecklist);
          this.safetyChecklist = safetyChecklist;
          this.safetyChecklistRegistry.set(safetyChecklist.id, safetyChecklist);
        }
        this.loadingInitial = false;
      });
      return safetyChecklist;
    } catch (error) {
      runInAction('get safety checklist error', () => {
        this.loadingInitial = false;
      });
      
      console.log(error);
    }
  };

  /*************************************
  * load funtions for Additional SOP Forms
  *************************************/


  @action loadSiteSafetyBriefing = async (id: string) => {
    this.loadingInitial = true;
    try {
      let siteSafetyBriefing = await agent.SafetyChecklist.siteSafetyDetail(id);
      runInAction('load existing ssb', () => {
        if(siteSafetyBriefing){
          this.siteSafetyBriefing = siteSafetyBriefing;
        }
        this.loadingInitial = false;
      });
      return siteSafetyBriefing;
    } catch (error){
      runInAction('get ssb error', () => {
        this.loadingInitial = false;
      });
      console.log(error);
    }
  };

  @action loadConfinedSpaceA = async (id: string) => {
    this.loadingInitial = true;
    try {
      let confinedSpaceA = await agent.SafetyChecklist.confinedSpaceDetail(id);
      runInAction('load existing csa', () => {
        if(confinedSpaceA){
          this.confinedSpaceA = confinedSpaceA;
        }
        this.loadingInitial = false;
      });
      return confinedSpaceA;
    } catch (error){
      runInAction('get csa error', () => {
        this.loadingInitial = false;
      });
      console.log(error);
    }
  };
  
  @action loadAerialLift = async (id: string) => {
    this.loadingInitial = true;
    try {
      let aerialLift = await agent.SafetyChecklist.aerialLiftDetail(id);
      runInAction('load existing al', () => {
        if(aerialLift){
          this.aerialLift = aerialLift;
        }
        this.loadingInitial = false;
      });
      return aerialLift;
    } catch (error){
      runInAction('get al error', () => {
        this.loadingInitial = false;
      });
      console.log(error);
    }
  }

  @action loadFallHazardAssessment = async (id: string) => {
    this.loadingInitial = true;
    try {
      let fha = await agent.SafetyChecklist.fallHazardDetail(id);
      runInAction('load existing al', () => {
        if(fha){
          this.fallHazardAssessment = fha;
        }
        this.loadingInitial = false;
      });
      return fha;
    } catch (error){
      runInAction('get fha error', () => {
        this.loadingInitial = false;
      });
      console.log(error);
    }
  }

  @action loadTrafficControlAssessment = async (id: string) => {
    this.loadingInitial = true;
    try {
      let tca = await agent.SafetyChecklist.trafficControlDetail(id);
      runInAction('load existing tca', () => {
        if(tca){
          this.trafficControlAssessment = tca;
        }
        this.loadingInitial = false;
      });
      return tca;
    } catch (error){
      runInAction('get tca error', () => {
        this.loadingInitial = false;
      });
      console.log(error);
    }
  }
  /*************************************
   *
   *************************************/
  
  @action loadSafetyChecklists = async () => {
    this.loadingInitial = true;

    try {
      const response = await agent.SafetyChecklist.list(this.axiosParams);
      let pagination = JSON.parse(response.headers.pagination);
      let count = pagination.totalItems;
      this.setCount(count);
      runInAction("loading safety checklists", () => {
        response.data.safetyChecklists.forEach((safetyChecklist: ISafetyChecklist) => {
          this.redefineDatesWithTimezones(safetyChecklist);
          safetyChecklist.created = new Date(safetyChecklist.created);
          safetyChecklist.estimatedStartDate = new Date(safetyChecklist.estimatedStartDate);
          safetyChecklist.estimatedCompletionDate = new Date(safetyChecklist.estimatedCompletionDate);
          this.safetyChecklistRegistry.set(safetyChecklist.id, safetyChecklist);
        });
        this.loadingInitial = false;
      });
    } catch (error) {
      runInAction("load safety checklists error", () => {
        this.loadingInitial = false;
      });
      console.log(error);
    }
  };

  /*************************************
   *
   *************************************/

  @computed get safetyChecklistsByProjectNum() {
    return this.groupSafetyChecklistsByProjectNum(
      Array.from(this.safetyChecklistRegistry.values())
    );
  }

  /*************************************
   *
   *************************************/

  get totalChecklists() {
    return this.safetyChecklistRegistry.size;
  }

  /*************************************
   *
   *************************************/

  groupSafetyChecklistsByProjectNum(safetyChecklists: ISafetyChecklist[]) {
    const sortedSafetyChecklists = safetyChecklists;

    return Object.entries(
      sortedSafetyChecklists.reduce((safetyChecklists, safetyChecklist) => {
        const projNum = safetyChecklist.projectNumber;
        safetyChecklists[projNum] = safetyChecklists[projNum]
          ? [...safetyChecklists[projNum], safetyChecklist]
          : [safetyChecklist];
        return safetyChecklists;
      }, {} as { [key: string]: ISafetyChecklist[] })
    );

  }

  /*************************************
   *
   *************************************/
  @action clearSafetyChecklist = () => {
    this.safetyChecklist = null;
  };


  @action clearSafetyChecklists = () => {
    this.safetyChecklistRegistry.clear();
  };


  /*************************************
   *
   *************************************/

  getSafetyChecklist = (id: string) => {
    return this.safetyChecklistRegistry.get(id);
  };

  /*************************************
   *
   *************************************/

  @action createSafetyChecklist = async (safetyChecklist: FormData) => {
    this.submitting = true;
    try {
      var sc = await agent.SafetyChecklist.create(safetyChecklist);
      runInAction("create safety checklist", () => {
        this.safetyChecklistRegistry.set(sc.id, sc);
        this.submitting = false;
      });

      history.push(`/safety-checklist/${sc.id}`);
      toast.success('New Safety Checklist Added.');

    } catch (error) {
      runInAction("create safety checklist error", () => {
        this.submitting = false;
      });

      toast.error("Problem submitting data");
      console.log(error);
    }
  };

/*************************************
 *
 *************************************/

  @action editSafetyChecklist = async (id: string, safetyChecklist: FormData) => {
    this.submitting = true;
    try {
      var sc = await agent.SafetyChecklist.update(id, safetyChecklist);
      
      runInAction("editing safety checklist", () => {
        this.redefineDatesWithTimezones(sc);
        this.safetyChecklistRegistry.set(sc.id, sc);
        this.safetyChecklist = sc;
        this.submitting = false;
      });
      history.push(`/safety-checklist/${sc.id}`);
      toast.success('Safety Checklist Edited.');
    } catch (error) {
      runInAction("edit safety checklist error", () => {
        this.submitting = false;
      });

      toast.error("Problem submitting data");
      console.log(error);
    }
  };

  @action deleteSafetyChecklist = async (id: string) => {
    this.submitting = true;
    try {
      var sc = await agent.SafetyChecklist.delete(id);
      runInAction("deleting safety checklist", () => {
        this.submitting = false;
      });
      history.push('/');
      toast.success('Safety Checklist Deleted.');
    } catch (error) {
      toast.error("Problem submitting data");
      console.log(error);
    }
  };

/*************************************
 *
 *************************************/

  @action addChemicalList = async (chemlist: IChemicalList) => {
  this.submitting = true;
  let id;
  try {
    await agent.SafetyChecklist.addChemicalList(chemlist);
    runInAction("add chemical list", () => {
      let tmp = this.safetyChecklistRegistry.get(chemlist.checklist_id)!;
      id = tmp.id;
      // TODO: WARNING: this flat-out replaces the existing chemical list attached to the checklist in the registry
      // this needs more robust checking for if a list already exists
      tmp.chemicalList = chemlist.list;
      this.safetyChecklistRegistry.set(chemlist.checklist_id, tmp);
      (this.safetyChecklist!).chemicalList = chemlist.list;
      this.submitting = false;
    });
    
      this.loadSafetyChecklist(`${id}`);
      history.push(`/safety-checklist/${id}`);
      toast.success('Chemical List added');
    } catch (error: any) {
      runInAction("add chemical list error", () => {
        this.submitting = false;
      });
      
      toast.error("Problem submitting data");
      console.error(error.response);
    }
  }

  @action addFallHaz = async (fallHaz: IServerBoundFallHaz) => {
    this.submitting = true;
    try {
      await agent.SafetyChecklist.addFallHaz(fallHaz);
      runInAction("add fall hazard", () => {
        this.loadSafetyChecklistDetail(fallHaz.checklist_id);
        this.submitting = false;
      });
      history.push(`/safety-checklist/${fallHaz.checklist_id}`);
      toast.success('Fall Hazard added');
    } catch (error: any) {
      runInAction("add fall Haz error", () => {
        this.submitting = false;
      });
      
      toast.error("Problem submitting data");
      console.error(error.response);
    }
  }
  
  @action addTrafficControl = async (traffic: IServerBoundTrafficControl) => {
    this.submitting = true;
    try {
      await agent.SafetyChecklist.addTrafficControl(traffic);
      runInAction("add traffic control", () => {
        this.loadSafetyChecklistDetail(traffic.checklist_id);
        this.submitting = false;
      });
      history.push(`/safety-checklist/${traffic.checklist_id}`);
      toast.success('Traffic Control added');
    } catch (error: any) {
      runInAction("add Traffic Control error", () => {
        this.submitting = false;
      });

      toast.error("Problem submitting data");
      console.error(error.response);
    }
  }

  @action addConfinedSpaceA = async (space: IServerBoundConfinedSpaceA) => {
    this.submitting = true;
    try {
      await agent.SafetyChecklist.addConfinedSpaceA(space);
      runInAction("add Confined Space A", () => {
        this.loadSafetyChecklistDetail(space.checklist_id);
        this.submitting = false;
      });
      history.push(`/safety-checklist/${space.checklist_id}`);
      toast.success('Confined Space A added');
    } catch (error: any) {
      runInAction("add Confined Space A error", () => {
        this.submitting = false;
      });
      toast.error("Problem submitting data");
      console.error(error.response);
    }
  }
          
  @action addSiteSafetyForm = async (siteSafety: IServerBoundSiteSafety) => {
    this.submitting = true;
    try {
      await agent.SafetyChecklist.addSiteSafety(siteSafety);
      runInAction("add site safety", () => {
        this.loadSafetyChecklistDetail(siteSafety.checklist_id);
        this.submitting = false;
      });
      history.push(`/safety-checklist/${siteSafety.checklist_id}`);
      toast.success('Site Safety Briefing added');
    } catch (error: any) {
      runInAction("add SSB error", () => {
        this.submitting = false;
      });
      toast.error("Problem submitting data");
      console.error(error.response);
    }
  }
  
  @action addAerialLift = async (aerialLift: IServerBoundAerialLiftChecklist) => {
    this.submitting = true;
    try {
      await agent.SafetyChecklist.addAerialLift(aerialLift);
      runInAction("add site safety", () => {
        this.loadSafetyChecklistDetail(aerialLift.checklist_id);
        this.submitting = false;
      });
      history.push(`/safety-checklist/${aerialLift.checklist_id}`);
      toast.success('Aerial Lift Checklist added');
    } catch (error: any) {
      runInAction("add Aerial Lift Checklist error", () => {
        this.submitting = false;
      });

      toast.error("Problem submitting data");
      console.error(error.response);
    }
  }

  @action addExcavForm = async (excav: IServerBoundExcavationChecklist) => {
    console.log('in the store')
    // this.submitting = true;
    
    // try {
      //   await agent.SafetyChecklist.addConfinedSpaceA(space);
      //   runInAction("add Confined Space A", () => {
        //     let tmp = this.safetyChecklistRegistry.get(space.checklist_id)!;
        
        //     tmp.confinedSpaceA = space.confinedSpaceA;
        //     this.safetyChecklistRegistry.set(space.checklist_id, tmp);
        //     (this.safetyChecklist!).confinedSpaceA = space.confinedSpaceA;
        //     this.submitting = false;
        //   });
        
        //   history.push("/");
        //   toast.success('Confined Space A added');
        // } catch (error: any) {
          //   runInAction("add Confined Space A error", () => {
            //     this.submitting = false;
            //   });
            
            //   toast.error("Problem submitting data");
            //   console.error(error.response);
            // }
    }

  @action addComment = async (comment: IServerBoundComment) => {
    this.submitting = true;
    try {
      await agent.SafetyChecklist.addComment(comment);
      runInAction("add comment", () => {
        this.loadSafetyChecklistDetail(comment.checklist_id);
        this.submitting = false;
      });
      history.push(`/safety-checklist/${comment.checklist_id}`);
      toast.success('Safety Checklist Comment added');
    } catch (error: any) {
      runInAction("add Safety Checklist Comment error", () => {
        this.submitting = false;
      });

      toast.error("Problem submitting data");
      console.error(error.response);
    }
  }

}
