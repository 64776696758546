import React, { useContext } from "react";
import { Button, Segment, Header, Form } from "semantic-ui-react";
import { RootStoreContext } from "../../app/stores/rootStore";
import { Form as FinalForm, Field } from "react-final-form";
import { IVehicleAccidentReport } from "../../app/models/vehicleAccidentReport";
import CheckboxInput from "../../app/common/form/CheckboxInput";

const DeleteVehicleAccidentReport: React.FC<{var: IVehicleAccidentReport}> = (p) => {
  const rootStore = useContext(RootStoreContext);
  const { closeModal } = rootStore.modalStore;
  const { deleteVehicleAccidentReport } = rootStore.vehicleAccidentReportStore;

  const labelText = 'Please confirm you wish to delete this Vehicle Accident Report.'

  const handleFinalFormSubmit = () => {
    Promise.all([
      deleteVehicleAccidentReport(p.var.id)
    ]);
    closeModal();
  }

  return (
    <Segment clearing>
      <FinalForm
        onSubmit={handleFinalFormSubmit}
        render={({ handleSubmit, values }) => (
          <Form onSubmit={handleSubmit}>
            <Header as='h3' content='Delete Vehicle Accident Report' />

            <Field component={CheckboxInput} name='confirm' label={labelText} type='checkbox'/>
          
            <div className='ui two buttons'>
              <Button color='red' onClick={() => closeModal()} content="Cancel" type='button' />
              <Button disabled={!values.confirm} color='green' content="Submit" type='submit' />
            </div>
          </Form>
        )}
      />
    </Segment>
  );
};

export default DeleteVehicleAccidentReport;
