import { observable, action, computed } from "mobx";
import { RootStore } from "./rootStore";

import { IAccountInfo } from "react-aad-msal";

export default class UserStore {
  rootStore: RootStore;
  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
  }

  @observable accountInfo: IAccountInfo | null = null;

  /*************************************
   *
   *************************************/

  @computed get accountInfoUserName() {
    return this.accountInfo?.account.name;
  }

  /*************************************
   *
   *************************************/

  @computed get accountInfoUserEmail() {
    return this.accountInfo?.account.userName;
  }

  /*************************************
   *
   *************************************/

  @action setAccountInfo = async (accountInfo: IAccountInfo) => {
    this.accountInfo = accountInfo;
  };
}
