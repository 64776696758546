import React, { Fragment } from "react";
import {
  TableRow,
  TableHeaderCell,
  TableHeader,
  TableCell,
  TableBody,
  Table,
} from 'semantic-ui-react'
import { observer } from "mobx-react-lite";

const ChemicalInvListDetails = (params: any) => {

	return (
    <Table celled>
      <TableHeader>
        <TableRow>
          <TableHeaderCell>Name</TableHeaderCell>
          <TableHeaderCell>Amounts</TableHeaderCell>
          <TableHeaderCell>Components</TableHeaderCell>
          <TableHeaderCell>Location</TableHeaderCell>
        </TableRow>
      </TableHeader>

      <TableBody>
        {params.chemicalList.map((chem: any) => (
          <TableRow>
            <TableCell>{chem.name}</TableCell>
            <TableCell>{chem.amounts}</TableCell>
            <TableCell>{chem.components}</TableCell>
            <TableCell>{chem.location}</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
	);
};

export default observer(ChemicalInvListDetails);
